<template>
    <div>
        <div class="journal-nav" v-if="!loading">
            <div>
                <img lazy-load :src="student.student_avatar">
            </div>
            <div>{{student.student_name}}</div>
            <div v-if="student.student_number">({{student.student_number}})</div>
            <!-- <van-icon name="arrow-up" /> -->
            <!-- <div class="journal-nav-time">{{student.student_number}}</div> -->
        </div>
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="listFinishedText"
            @load="onLoad"
            style="margin-bottom:20px"
            >
            <div v-for="(item,index) in list" :key="index" class="schedule-comment-list">

                <div class="schedule-comment-list-nav">
                    <avatar :src="item.creater.teacher_avartar" color="#fff" :size="28"></avatar>
                    <div class="schedule-comment-list-name">
                        <!-- <div style="font-size:12px"></div> -->
                        <div class="schedule-comment-list-time">{{item.creater.teacher_name}}·{{item.message_created_at}}</div>
                    </div>
                </div>
                <div class="schedule-comment-list-content">
                    {{item.message_content}}
                </div>

                <div class="schedule-comment-list-img">
                    <van-image
                    v-for="(it,j) in item.message_images"
                    :key="j"
                    @click="toPreview(item.message_images,j)"
                    width="65"
                    height="65"
                    fit="contain"
                    :src="it"
                    />
                </div>

                <!-- <div  class="list-item" @click="play(item)"> -->
                    
                <div class="schedule-comment-list-video" v-if="item.message_video.length>0">
                    <div :id="`dplayer_${item.id}_${index}`" v-for="message_video,index in item.message_video" :key="index" />
                </div>

                <div @click="toLike(item)" class="schedule-comment-list-like">
                    <van-icon name="good-job-o" :color="item.message_is_liked?'#00cca2':''" />
                    <span :style="{marginLeft:'10px',color:item.message_is_liked?'#00cca2':''}">{{item.like_count}}</span>
                </div>

                  <wx-open-launch-weapp
                  v-if="item.message_video_download[0]"
                  id="launch-btn"
                  :path='item.message_video_download[0]'
                  username="gh_704767f97fd7"
                >
                  <script type="text/wxtag-template">
                    <div role="button" tabindex="0" class="van-cell van-cell--clickable van-cell--small"><div class="van-cell__title" style="font-size: 12px;color: rgb(153, 153, 153);"><span>下载视频</span></div><i class="van-icon van-icon-arrow van-cell__right-icon"><!----></i></div>
                  </script>
                </wx-open-launch-weapp>
                
                <!-- <div style='width:86px;height:86px;'></div> -->
                <van-cell title="上课内容" v-if="item.book_id" size="small" title-style="color: #999;" is-link @click="getSummary(item)" />

            </div>
            <van-empty v-if="list.length == 0 && !loading" description="暂无相关数据~" />
        </van-list>
        <!-- <div 
            class="float-ball"
            ref="ball"
            @touchstart.stop='handleTouchstart'
            @touchmove.stop='handleTouchmove'
            @touchend.stop='handleTouchend'
            @click.stop="show = true;isConfirm = false"
            :style="{top:`${moveTop}px`,left:`${moveLeft}px`}"
            >
            <van-icon color="#00cca2" name="add-o" />
        </div> -->
        <van-popup v-model="showSummary" round position="bottom" :style="{ height: '85%' }" >
            <div class="schedule-detail-html" v-if="html">
                <div @click="toRichPreview($event)" class="ql-editor" v-html="html"></div>
            </div>
            <div v-else>
                <van-empty description="暂无相关上课内容~" />
            </div>
        </van-popup>
        <van-popup v-model="show" position="bottom" :style="{ height: '100%' }" >
            <div class="schedule-comment-popup">
                <div class="schedule-comment-popup-nav">
                    <div @click="cancel">
                        <van-button size="small" type="default">取消</van-button>
                    </div>
                    <div>
                        <van-button :loading="isConfirm" @click="confirm" size="small" type="primary">确认</van-button>
                    </div>
                </div>
                <div class="schedule-comment-popup-form">

                    <van-form @submit="confirm" :colon='true'>
                        <van-field
                            v-model="message"
                            rows="2"
                            autosize
                            label="文字评论"
                            name="content"
                            type="textarea"
                            maxlength="1000"
                            placeholder="请输入评论内容"
                            show-word-limit
                            class="survey-van-textarea-boeder"
                            :required='true'
                            :rules="[{ required: true, message: '请输入' }]"
                        />
                        <van-field 
                            name="image"
                            label="图片评论"
                            :required='false'
                            >
                            <template #input>
                                <van-uploader :after-read="e=>afterRead(e)" :before-delete='(e,b)=>beforeDelete(e,b)' multiple :max-count="9" v-model="imgList" />
                            </template>
                        </van-field>
                    </van-form>
                </div>

            </div>
        </van-popup>
        <loadings :loading='searchLoading' />
        <!-- 底部导航栏 -->
        <Tabbar/>
        <copyright v-show='!searchLoading' />
    </div>
</template>

<script>
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import upload from '@/utils/upload'
    import { Popup,Field,Empty,Form,Uploader,ImagePreview } from 'vant'
    import Avatar from 'vue-avatar'
    import DPlayer from 'dplayer';
    import Loadings from '@/components/loading'
    import Copyright from '@/components/Copyright'
    import Tabbar from '@/components/Tabbar'
    import {addRandomAndGetPreviewImageUrlsFromRichText} from '@/utils/imgPreview'
    export default {
        name:'message',
        data() {
            return {
                moveTop:415,
                moveLeft:317,
                show:false,
                showSummary:false,
                loading:false,
                dp:[],
                isConfirm:false,
                searchLoading:true,
                student:{},
                message:'',
                list:[],
                finished: false,
                listFinishedText: '没有更多了',
                pageSize: 10,
                pageCurrent: 1,
                html:'',
                richImages:[],
                book_id:'',
                comment_entity:'',
                imgList:[]
            }
        },
        components: {
            Copyright,
            [Form.name]: Form,
            [Empty.name]: Empty,
            [Field.name]: Field,
            [Popup.name]: Popup,
            [Uploader.name]: Uploader,
            Tabbar:Tabbar,
            [Avatar.name]: Avatar,
            loadings:Loadings,
        },
        created () {
            this.book_id = this.$route.query.book_id
        },
        beforeDestroy() {
            this.dp.forEach(dpayer=>{
            if(dpayer) {
                dpayer.destroy()
            }
            })
        },
        methods: {
            getSummary(item){
                this.showSummary=true;
                this.$api.schedule_summary({book_id:item.book_id}).then(res => {
                    if(res.data.class_summary){
                        let arr = addRandomAndGetPreviewImageUrlsFromRichText(res.data.class_summary)
                        this.html = arr[0]
                        this.richImages = arr[1]
                        // console.log(this.richImages);
                    }
                    // this.student=res.data.student
                })
            },
            getDetail(){
                this.$api.profile_index().then(res => {
                    this.student=res.data.student
                })
            },
            getList(){
                this.loading = true
                this.$api.message_index({ 'per-page': this.pageSize, page: this.pageCurrent,book_id:this.book_id})
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    // this.comment_entity = res._data.comment_entity
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    
                    this.$nextTick(() => {
                        this.list.map(item=>{
                            item.message_video.map((video , index)=>{
                                let option = {
                                    container: document.getElementById('dplayer_'+item.id+'_'+index),
                                    mutex:false,
                                    autoplay:false,
                                    preload:'auto',
                                    volume:0.5,
                                    screenshot: false,
                                    live: false,
                                    video: {
                                        url: video,
                                        type: 'mp4',
                                        pic:video+'?vframe/png/offset/0'
                                    }
                                }
                                this.dp.push(new DPlayer(option))
                            })
                        })
                    })

                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : ''
                    this.pageCurrent += 1
                })
            },
            onLoad() {
                this.getDetail()
                this.getList()
            },
            handleTouchstart(e){
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchmove(e){
                e.preventDefault()
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchend(e){
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            toRichPreview(e){
                if (e.target.tagName == 'IMG') {
                    WeixinJSBridge.invoke("imagePreview", {
                        "urls":this.richImages,
                        "current": this.richImages[e.target.src.split('random=')[1] - 1]
                    });
                }
            },
            afterRead(file,name) {
                this.$forceUpdate()
                let that = this
                this.isConfirm = true
                if(!file.length){
                    upload({ file: file.file })
                    .then(res => {
                        file.path  = res.fileName
                    })
                    .catch(err => {
                        that.$toast({ message: '图片上传失败~', duration: 1500 })
                    })
                }else{
                    file.forEach(it=>{
                        that.isConfirm = true
                        upload({ file: it.file })
                            .then(res => {
                                it.path  = res.fileName
                            })
                            .catch(err => {
                                that.$toast({ message: '图片上传失败~', duration: 1500 })
                            })
                    })
                }
                setTimeout(()=>{
                    that.isConfirm = false
                },1500)
            },
            beforeDelete(file,detail,name){
                this.imgList.splice(detail.index,1)
                this.$forceUpdate()
                return true
            },
            toPreview(item,index){
                WeixinJSBridge.invoke("imagePreview", {
                    "urls":item,
                    "current": item[index]
                })
            },
            toLike(item){
                if(item.commnet_is_liked){
                    item.commnet_is_liked = false
                    item.commnet_like_count--
                }else{
                    item.commnet_is_liked = true
                    item.commnet_like_count++
                }
                this.$api.message_like_or_cancel({message_id:item.id})
                .then(res=>{
                    console.log(item)
                    item.like_count=res.data.like_count
                })
                .catch(err=>{})
            },
            cancel(){
                this.message = ''
                this.show = false
            },
            confirm(){
                if(!this.message){
                    this.$toast('请输入评论内容～')
                    return false
                }
                let obj = {
                    comment_content:this.message,
                    comment_entity:this.comment_entity,
                    comment_image:[]
                }
                if(this.imgList.length>0){
                    obj.comment_image = this.imgList.map((item)=>{return item.path})
                }
                this.loading = true
                this.isConfirm = true
                this.$api.schedule_addcomment(obj)
                .then(res=>{
                    this.finished = false
                    this.list = []
                    this.imgList = []
                    this.pageCurrent = 1
                    this.$toast('操作成功～')
                    this.getList()
                    this.message = ''
                    this.show = false
                })
            }
        },
    }
</script>

<style lang="less" scoped>
    .journal-nav{
        background: #fff;
        padding: 0 15px;
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #eee;
        img{
            margin-left: 15px;
            width: 28px;
            height: 28px;
            border-radius: 2px;
            margin-right: 5px;
        }
        .journal-nav-time{
            position: absolute;
            right: 0;
        }
    }
    .schedule-comment-list{
        // width: 90%;
        background: #fff;
        // margin: 0 auto;
        margin-top: 15px;
        // border-radius: 10px;
        padding: 10px;
        box-shadow: 0 0 2px 3px hsla(0,0%,86.3%,.1);
        position: relative;
        &-nav{
            display: flex;
            align-items: center;
        }
        &-name{
            margin-left:15px;
        }
        &-time{
            font-size: 12px;
            color: #999;
        }
        &-content{
            margin-top:10px;
            word-break: break-all;
        }
        &-img{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .van-image{
                margin: 10px;
            }
        }
        &-like{
            position: absolute;
            right: 10px;
            top: 10px;
            display: flex;
            align-items: center;
        }
    }
    .schedule-comment-popup{
        &-nav{
            display: flex;
            justify-content: space-between;
            padding: 10px;
        }
    }
    .float-ball{
        position: fixed;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 2px #999;
        .van-icon{
            font-size: 0.8rem;
        }
    }
</style>

<style lang="less">
    .schedule-detail-html{
      margin-top: 10px;
      img{
        max-width: 100%;
      }
    }
    .schedule-comment-popup-form{
        .van-cell{
            display: block;
        }
        .van-field__label{
            width: 100%;
        }
        .survey-van-field-boeder{
            .van-field__body{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
        .survey-van-textarea-boeder{
            .van-cell__value{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
    }
</style>